@import "_palette.scss";
@import "_sizes.scss";

.section-payment {
  grid-column: 1 / span 4;
  grid-row: 1 / span 1;
  grid-template-columns: 1fr 1fr;
  display: grid;
  max-height: 560px;
  overflow: hidden;
  grid-template-areas:
          "text text"
          "calendar summary"
    ;
  @media only screen and (max-width: 720px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    max-height: none;
    grid-template-areas:
            "text"
            "calendar"
            "summary"
    ;
  }

  .text {
    grid-area: text;
    margin-bottom: 15px;
    font-size: 15px;
    text-align: left;
  }
  .calendar-area {
    grid-area: calendar;
  }
  .summary-area {
    grid-area: summary;
  }

  .transfers__button--table {
    font-size: 14px;
    padding: 0.5em;
    cursor: pointer;
    position: absolute;
    bottom: 10%;
    right: 4em;
  }

  .division-line {
    border: 1px solid $light-grey3;
    grid-column: 1 / span 2;
  }
}

.table-content {
  grid-template-columns: 1fr;
  position: relative;
  overflow: hidden;

  .content__header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    .receivables-count {
      text-align: left;
    }

    .header--align-right {
      grid-column: 2;
      display: flex;
      justify-content: flex-end;

      .table__button {
        border: 1px solid $blue;
        border-radius: 0.231em;
        color: $blue;
        font-size: 14px;
        height: 2.462em;
        cursor: pointer;
        background: $white;

        &:hover {
          background: $blue;
          color: $white;
        }

        &.button--export-file {
          width: 9em;
        }

        &.button--transfers {
          min-width: 12em;
          margin-right: 1em;
        }
      }
    }
  }
}
.full-table{
  text-align: center;
  div.rt-table .rt-thead .rt-th.-sort-asc{
    box-shadow: none;
  }
  .view-installments{
    border: none;
    background-color: transparent;
    color: $blue;
    font-size: 13px;
    cursor: pointer;

    &:hover{
      text-decoration: underline;
    }
  }
}

.receivables{
  &__installments, &__installment-detail, &__transaction-details{
    grid-column: 1 / span 4;
  }

  &__transaction-details {
    padding: 0 20px;
    .back-button {
      margin-bottom: 1em;
    }
  }
}

.payments-calendar {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1em;
  .react-calendar {
      min-height: 420px;
      &__month-view {
        &__weekdays__weekday {
          text-align: center;
          font-size: $size-20;
          color: $dark-grey;
          height: $size-24;
          text-transform: capitalize;
        }
        &__days__day--neighboringMonth {
          .react-calendar__tile__content {
            opacity: 0.3;            
          }
        }
      }

      &__navigation {
        margin-top: $size-20;
        margin-bottom: $size-30;
        height: $size-22;
        &__arrow {
          border: 1px solid $light-grey3;
          outline: none;
        }
        &__prev2-button {

          display: none;
        }
        &__next2-button {
          display: none;
        }
        &__next-button {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
        &__prev-button {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
        &__label {
          color: $medium-grey;
          background-color: white;
          font-size: $size-22;
          font-weight: 500;
          border: 1px solid white;
          &:first-letter {
            text-transform: capitalize;
          }
        }
        &__arrow {
          border-radius: $size-22;
          border-color: $light-grey4;
          background-color: $light-grey4;
          width: 22px;
          height: 22px;
          div {
            font-size: $size-17;
            color: $grey-font2;
            letter-spacing: 0.5px;
          }
        }
      }

      &__tile {
        border: 1px solid $light-grey3;
          height: 56px;
          width: 56px;
        background-color: white;
        -webkit-appearance: none;
          -moz-appearance: none;
          -ms-progress-appearance: unset;
        time {
          display: none;
        }
        &__content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 36px;
          padding-top: 8px;
          margin-bottom: 12px;
          &__date {
            font-size: $size-19;
          }
          &__payment {
            position: relative;
            color: white;
            font-size: $size-13;
            border-radius:  $size-7;
            font-weight: 300;
            padding: 0.05em;
            letter-spacing: -0.5px;
            &__green {
              background-color: $green;
              color: black;
            }
            &__red {
              background-color: $red;
            }
            &__white {
              background-color: #999;
              color: white;
            }
            &__yellow {
              background-color: $yellow;
              color: black;
            }
            &__none {
              background-color: $dark-grey;
            }

          }
        }
        &::-moz-focus-inner {
          border: 0
        }
        &:focus {
          outline: none;
        }

        &--active:not(.react-calendar__month-view__days__day--neighboringMonth) {
          background: #f5f5f5;
          box-shadow: inset 3px 3px 8px 0 rgba(121, 121, 121, 0.27);
        }
        &:nth-child(1n + 0) {
          border-left-style: none;
        }
        &:nth-child(7n + 0) {
          border-right-style: none;
        }
        &:nth-child(n+8) {
          border-top-style: none;
        }
        &:first-child {
          border-left-style: none;
        }
      }
  }
  &__legends {
    display: flex;
    font-size: 18px;
    &__legend {
      display: flex;
      align-items: center;
      margin-right: $size-15;
      &__circle {
        border-radius: 50%;
        height: 10px;
        width: 10px;
        &__green {
          background-color: $green;
        }
        &__red {
          background-color: $red;
        }
        &__yellow {
          background-color: $yellow;
        }
        &__none {
          background-color: $grey-font2;
        }
      }
      &__text {
        margin-left: $size-3;
        color: $grey-font2;
        font-size: $size-10;
      }
    }
  }
}

div.react-calendar__month-view__days > button > abbr{
  display: none;
}

abbr[title], abbr[data-original-title]{
  text-decoration: none;
}